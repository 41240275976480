import {Module} from "@/app/editor/module";
import {message} from "ant-design-vue";
import {Activity} from "@/app/editor/activity";
import {engine} from "@/app/editor/material/imager";
import {req} from "@/lib/util";
import {IActivityData} from "@/lib/activity";


export class Editor {
    readonly activity: Activity
    readonly modules: Module[]
    private initCode: number

    constructor(a: IActivityData) {
        this.activity = new Activity(a)
        this.modules = this.activity.modules
        engine.bindActivity(this.activity)
        this.initCode = this.activity.hash(JSON.stringify(this.activity.data))

    }

    isEdit(): boolean {
        return !!this.activity.data.activity_id
    }

    isChange(): boolean {
        return this.activity.hash(JSON.stringify(this.activity.data)) != this.initCode
    }

    save() {
        Promise.all([this.activity.validate(), ...this.modules.map(m => m.validate())]).then(() => {
            // const l = message.loading('处理中...', 0)
            // req({
            //     url: 'editor/activity', method: 'post', data: this.activity.data, success: rs => {
            //         this.initCode = this.activity.hash(JSON.stringify(this.activity.data))
            //         if (rs.activity_id)
            //             router.push({path: '/activity/share', query: {activity_id: rs.activity_id}}).then()
            //     }, complete: l
            // })
        }).catch(e => {
            console.error(e)
            // message.error({content: e.errorFields[0].errors[0]}).then(null)
        })
    }

    saveDraft() {
        if (this.isEdit()) return
        const l = message.loading('保存中...', 0)
        req({
            url: 'editor/draft', method: 'post', data: this.activity.data, success: (r) => {
                this.activity.data.draft_id = r.draft_id
                this.initCode = this.activity.hash(JSON.stringify(this.activity.data))
                message.success('已存入草稿').then()
            }, complete: l
        })
    }

    validate(): Promise<unknown> {
        // for (let i = 0; i < this.modules.length; i++) {
        //     const e = this.modules[i].validate()
        //     if (e != null) {
        //         message.error({content: e.message})
        //         return false
        //     }
        // }
        console.log('validate', [this.activity.validate(), ...this.modules.map(m => m.validate())])
        return Promise.all([this.activity.validate(), ...this.modules.map(m => m.validate())])
        // return this.activity.validate()
    }

}

export const editor = {editor: null as Editor | null}