import {RouteLocationNormalized, RouteRecordRaw} from "vue-router";
import TabBarView from "@/components/TabBarView.vue";
import TemplatesSide from "@/app/templates/TemplatesSide.vue";
import dayjs, {Dayjs} from "dayjs";
import TemplatesPanelView from "@/app/templates/TemplatesPanelView.vue";
import {reactive, watch} from "vue";
import {req} from "@/lib/util";
import {message} from "ant-design-vue";
import emit from "@/lib/emit";
import {ViewCard} from "vue-waterfall-plugin-next/dist/types/types/waterfall";

export default <Array<RouteRecordRaw>>[
    {
        path: '/',
        name: 'templates',
        meta: {requiresAuth: true},
        components: {
            default: TemplatesPanelView,
            side: TemplatesSide,
            tabBar: TabBarView,
        },
        beforeEnter: (to, _, next) => {
            tr.prepare(to)
            return next()
        },
        children: []
    }
]

export const tagsFestival: { [key: string]: string } = {
    'j_nsj': '女神节',
    "j_wyldj": "劳动节",
    'j_dwj': '端午节',
    'j_618': '618',
    'j_zqj': '中秋节',
    'j_gqj': '国庆节',
    'j_ssy': '双11',
    'j_1212': '双12',
    'j_yd': '元旦',
    'j_mqj': '母亲节',
    'j_sdj': '圣诞节',
    'j_gej': '感恩节',
    // 't_nzdc': '年中大促',
}
export const tagsFestivalDate: { [key: string]: Dayjs } = {
    // 'j_wsj': dayjs('2023-11-01', 'YYYY-MM-DD'),
    // 'j_cjzs': dayjs('2024-03-31', 'YYYY-MM-DD'),
    // 'j_zsj': dayjs('2024-03-12', 'YYYY-MM-DD'),
    'j_ssy': dayjs('2024-11-11', 'YYYY-MM-DD'),
    'j_gej': dayjs('2024-11-28', 'YYYY-MM-DD'),
    'j_1212': dayjs('2024-12-12', 'YYYY-MM-DD'),
    'j_sdj': dayjs('2024-12-25', 'YYYY-MM-DD'),
    'j_yd': dayjs('2025-01-01', 'YYYY-MM-DD'),
    'j_nsj': dayjs('2025-03-08', 'YYYY-MM-DD'),
    'j_wyldj': dayjs('2025-05-01', 'YYYY-MM-DD'),
    'j_mqj': dayjs('2025-05-11', 'YYYY-MM-DD'),
    'j_dwj': dayjs('2025-05-31', 'YYYY-MM-DD'),
    'j_618': dayjs('2025-06-18', 'YYYY-MM-DD'),
    // 't_nzdc': dayjs('2025-08-20', 'YYYY-MM-DD'),
    'j_gqj': dayjs('2025-10-01', 'YYYY-MM-DD'),
    'j_zqj': dayjs('2025-10-06', 'YYYY-MM-DD'),
}
export const tagsModel: { [key: string]: string } = {
    'm_ptt': '团购',
    'm_jgjtt': '价格阶梯团',
    'm_spjtt': '商品阶梯团',
}

export interface IIndustryGroup {
    name: string
    tags: string[]
}

export const tagsIndustryGroup: { [key: string]: IIndustryGroup } = {
    'tg_sejp': {
        name: '少儿教培',
        tags: ['t_sems', 't_sesf', 't_sezc', 't_sekc', 't_sewd', 't_semt', 't_sety', 't_seyq', 't_selg', 't_sebc']
    },
    'tg_zbhj': {name: '珠宝黄金', tags: ['t_zbhj']},
    'tg_mrmf': {name: '丽人美业', tags: ['t_mr', 't_tdt', 't_mf', 't_mj', 't_cr']},
    'tg_cy': {name: '餐饮小吃', tags: ['t_sk', 't_jcc', 't_mx', 't_lsf', 't_mlt', 't_hg', 't_dg', 't_nc']},
    'tg_qt': {name: '更多行业', tags: ['t_hssy', 't_zy', 't_xc']},
    // 'tg_zbhj': {name: '珠宝黄金', tags: ['t_zbhj']},
    // 'tg_hssy': {name: '婚纱摄影', tags: ['t_hssy']},
    // 'tg_zy': {name: '足浴', tags: ['t_zy']},
    // 'tg_xc': {name: '洗车', tags: ['t_xc']},
}
export const tagsIndustry: { [key: string]: string } = {
    't_sems': '少儿美术',
    't_sesf': '少儿书法',
    't_sezc': '少儿主持',
    't_sekc': '少儿口才',
    't_sewd': '少儿舞蹈',
    't_semt': '少儿模特',
    't_sety': '少儿体育',
    't_seyq': '少儿乐器',
    't_selg': '少儿乐高',
    't_sebc': '少儿编程',
    't_mf': '美发',
    't_mr': '美容',
    't_tdt': '头道汤',
    't_sk': '烧烤',
    't_jcc': '家常菜',
    't_mx': '米线',
    't_lsf': '螺蛳粉',
    't_mlt': '麻辣烫',
    't_hg': '火锅',
    't_zbhj': '珠宝黄金',
    't_hssy': '婚纱摄影',
    't_dg': '甜点',
    't_nc': '奶茶',
    't_mj': '美甲',
    't_zy': '足浴',
    't_cr': '采耳',
    't_xc': '洗车'
}
export const tagsTarget: { [key: string]: string } = {
    't_tkhd': '拓客活动',
    't_cfj': '宠粉节',
    't_tgj': '团购节',
    't_xdky': '新店开业',
    't_znqd': '周年庆典',
    't_nzdc': '年中大促',
}

export const cardTags = {...tagsFestival, ...tagsModel, ...tagsIndustry, ...tagsTarget}

export interface IThemeCard {
    theme_id: string
    tags: string[]
}

export interface IItem {
    title: string
    tag: string
}

class ThemeRecommend {
    readonly args = reactive({module: '', target: '', industry_group: '', industry: ''})
    readonly status = reactive({loading: false, industry: [] as IItem[]})
    readonly modules: IItem[]
    readonly targets: IItem[]
    readonly industryGroup: IItem[]
    private readonly source = [] as IThemeCard[]

    constructor() {
        this.modules = Object.keys(tagsModel).map(k => ({title: tagsModel[k], tag: k}))
        this.targets = Object.keys(tagsTarget).map(k => ({title: tagsTarget[k], tag: k}))
        Object.keys(tagsFestivalDate)
            .filter(d => tagsFestivalDate[d].isAfter(dayjs().add(-7, 'days')))
            // .slice(0, 3)
            .forEach(d => this.targets.push({title: tagsFestival[d], tag: d}))
        this.industryGroup = Object.keys(tagsIndustryGroup).map(k => ({title: tagsIndustryGroup[k].name, tag: k}))
        this.status.industry = tagsIndustryGroup[this.industryGroup[0].tag].tags.map(t => ({
            title: tagsIndustry[t],
            tag: t
        }))

        const c = JSON.parse(window.localStorage.getItem("args") ?? 'null')
        this.args.module = c?.module ? c.module : this.modules[0].tag
        this.args.target = c?.target ? c.target : this.targets[0].tag
        this.args.industry_group = c?.industry_group ? c.industry_group : this.industryGroup[0].tag
        this.args.industry = c?.industry ? c.industry : this.status.industry[0].tag

        this.status.industry = tagsIndustryGroup[this.args.industry_group].tags.map(t => ({
            title: tagsIndustry[t],
            tag: t
        }))
        this.onChange(this.args)

        watch(this.args, d => {
            this.onChange(d)
        })
    }

    private onChange(args: { [key: string]: string }) {
        this.status.industry = tagsIndustryGroup[this.args.industry_group].tags.map(t => ({
            title: tagsIndustry[t],
            tag: t
        }))
        if (!this.status.industry.find(i => i.tag == this.args.industry))
            this.args.industry = this.status.industry[0].tag
        window.localStorage.setItem('args', JSON.stringify(args))
    }

    content(tag: string): ViewCard[] {
        const arr = this.source.filter(i => i.tags.includes(this.args.module) && i.tags.includes(this.args.target) && i.tags.includes(tag)).map(i => ({src: `https://activity.guandb.cn/media/image/theme/preview/${i.theme_id}_${this.args.module}_${this.args.target}_${tag}.jpg`, ...i})) ?? []
        arr.sort(() => Math.random() - 0.5)
        arr.push({src: require('@/assets/image/empty_theme.jpg'), theme_id: '', tags: [this.args.module, this.args.target, tag]})
        return arr
    }

    tag(tags: string[], ts: string): string {
        return tags.find(t => Object.keys(tagsModel).includes(t)) ?? ''
    }

    prepare(to: RouteLocationNormalized) {
        const c = JSON.parse(window.localStorage.getItem("args") ?? 'null')
        to.query.industry_group = to.query.industry_group ? to.query.industry_group : c?.industry_group ? c.industry_group : Object.keys(tagsIndustryGroup).first()
        to.query.industry = to.query.industry ? to.query.industry : c?.industry ? c.industry : Object.keys(tagsIndustry).first()
        to.query.target = to.query.target ? to.query.target : c?.target ? c.target : Object.keys(tagsTarget).first()
        to.query.model = to.query.model ? to.query.model : c?.model ? c.model : Object.keys(tagsModel).first()
    }

    load() {
        const fn = message.loading('载入中')
        this.status.loading = true
        req({
            url: 'editor/material/themes', success: rs => {
                this.source.length = 0
                this.source.push(...rs)
            }, complete: () => {
                fn()
                this.status.loading = false
                emit.emit('templates.list.loaded')
            }
        })
    }
}

export const tr = new ThemeRecommend()