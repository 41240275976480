import {IActivityGift} from "@/app/editor/material/gift";
import {Module} from "@/app/editor/module";
import {DefineComponent, UnwrapRef} from "vue";
import {Activity} from "@/app/editor/activity";
import {IModuleData} from "@/lib/activity";
import {account} from "@/app/login";
import RecommendPreview from "@/app/editor/module/recommend/v2/RecommendPreview.vue";
import RecommendSetupView from "@/app/editor/module/recommend/v2/RecommendSetupView.vue";

export interface IDistributionRuleProps {
    require: 'none' | 'pay'
    bind: 'view' | 'order' | 'pay'
    show_list: 'hide' | 'count' | 'sum'
}

export interface IDistributionRule {
    enable: boolean
    percents: number[]
    props: IDistributionRuleProps
}

export interface IGiftRule {
    img_url: string | null
    title: string
    value: number
    amount: number
    freshman_count: number
    gift: IActivityGift | null
}

export interface IRecommendConfig {
    distribution_rule: IDistributionRule
    gift_rules: IGiftRule[]
}

export class Recommend extends Module {
    cfg: UnwrapRef<IRecommendConfig>;

    constructor(a: Activity, m: UnwrapRef<IModuleData>) {
        super(a, m, '推荐奖励', 'module_recommend', RecommendPreview as DefineComponent, RecommendSetupView as DefineComponent);
        this.cfg = m.config as IRecommendConfig
        while (this.cfg.distribution_rule.percents.length<3) {
            this.cfg.distribution_rule.percents.push(0);
        }
    }

    max(): number {
        return Math.floor((account.shop?.wx_mch_profit_sharing_max_ratio ?? 3000) / 100) - 1
    }

    distributionAvailable(): boolean {
        return !!account.shop?.wx_mch_id && !!account.shop.wx_mch_profit_sharing_max_ratio
    }

    unit(): string {
        return this.activity.data.tags.find(t => t.startsWith('t_se')) ? '生' : '客'
    }
}