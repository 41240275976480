import {Module} from "@/app/editor/module";
import {DefineComponent, UnwrapRef, watch} from "vue";
import {Activity} from "@/app/editor/activity";
import {IModuleData} from "@/lib/activity";
import {IActivityGift} from "@/app/editor/material/gift";
import GroupPreview from "@/app/editor/module/group/v2/GroupPreview.vue";
import GroupSetupView from "@/app/editor/module/group/v2/GroupSetupView.vue";

export interface IGroupProps {
    buy_bottom_text: string
    customer_buy_limit: number
    goods_validity_mons: number
    is_only_regular_customer_can_be_leader: boolean
    is_only_freshman_can_be_join: boolean
    group_time_limit_hours: number
    group_info_picture: string[]
}

export interface IGroupStep {
    group_size: number
    price: number
}

export interface IGroupGoods {
    img_url: string | null
    title: string
    value: number
    // 阶梯级别：[团长，成员]
    amount: { [key: number]: number[] }
    gift: IActivityGift | null
}

export interface IGroupConfig {
    category: '普通团' | '价格阶梯团' | '商品阶梯团'
    stock: number
    sell_price: number
    leader_free: boolean
    steps: IGroupStep[]
    goods: IGroupGoods[]
    props: IGroupProps
}

export class Group extends Module {
    cfg: UnwrapRef<IGroupConfig>;

    constructor(a: Activity, m: UnwrapRef<IModuleData>) {
        super(a, m, '团购设置', 'fuwu', GroupPreview as DefineComponent, GroupSetupView as DefineComponent);
        this.cfg = m.config as UnwrapRef<IGroupConfig>

        // 公共属性绑定
        this.activity.status.buy_button_text = this.cfg.props.buy_bottom_text
        this.activity.status.sell_price = this.cfg.sell_price
        const i = this.data.i
        this.activity.status.rules[i] = [
            `每人限购${this.cfg.props.customer_buy_limit}笔`,
            `购买后${this.cfg.props.goods_validity_mons}个月内到店消费有效`,
            `开团后${this.cfg.props.group_time_limit_hours}小时内不成团，视为未成团`
        ]
        if (this.cfg.props.is_only_regular_customer_can_be_leader) this.activity.status.rules[i].push('仅允许老会员开团')
        if (this.cfg.props.is_only_freshman_can_be_join) this.activity.status.rules[i].push('仅允许新客参团')
        watch(this.cfg, c => {
            this.activity.status.buy_button_text = c.props.buy_bottom_text
            this.activity.status.sell_price = c.sell_price
            this.activity.status.rules[i] = [
                `每人限购${c.props.customer_buy_limit}笔`,
                `购买后${c.props.goods_validity_mons}个月内到店消费有效`,
                `开团后${c.props.group_time_limit_hours}小时内不成团，视为未成团`
            ]
            if (c.props.is_only_regular_customer_can_be_leader) this.activity.status.rules[i].push('仅允许老会员开团')
            if (c.props.is_only_freshman_can_be_join) this.activity.status.rules[i].push('仅允许新客参团')
        })
    }

    variable(name: string): string | null {
        switch (name) {
            case '拼团价':
                return this.cfg.sell_price.toString()
            case '拼团类型':
                return this.cfg.category
            case '商品价值':
                if (this.cfg.steps.length==0) return '0'
                return this.cfg.goods.reduce((a,b) => a + b.value*(b.amount[this.cfg.steps.length-1].length == 2 ? b.amount[this.cfg.steps.length-1][1]:1 ),0).toString()
        }
        return super.variable(name);
    }
}