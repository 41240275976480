import {Module} from "@/app/editor/module";
import {reactive, UnwrapRef} from "vue";
import {DefineComponent} from "@vue/runtime-core";
import {ITitle} from "@/app/editor/material/title";
import BasicStyleSetup from "@/app/editor/module/basic/v2/BasicStyleSetup.vue";
import {Activity} from "@/app/editor/activity";
import {Json} from "@/lib/util";
import BasicStylePreview from "@/app/editor/module/basic/v2/BasicStylePreview.vue";
import {Random} from "mockjs";
import {IModuleData} from "@/lib/activity";

interface IBasicAttach {
    titles: ITitle[]
}

export interface IBasicVirtual {
    view: number
    sign: number
    share: number
    pay: number
}

export interface IBasicProps {
    show_barrage: boolean
    show_statistics: boolean
    virtual: IBasicVirtual
    is_show_pay_history: boolean
}


export interface IBasicConfig {
    music_url: string
    props: IBasicProps
}

interface IMusicDataCategoryItem {
    name: string
    url: string
}

interface IMusicDataCategory {
    name: string
    data: IMusicDataCategoryItem[]
}

export interface IMusicData {
    child: IMusicDataCategory
    classical: IMusicDataCategory
    festival: IMusicDataCategory
    hot: IMusicDataCategory
    natural: IMusicDataCategory
    piano: IMusicDataCategory
    wangluo: IMusicDataCategory
}

export interface IPrivateData {
    audio_url: string
    audio_status: boolean
}

export class Basic extends Module {
    readonly attach: IBasicAttach
    cfg: UnwrapRef<IBasicConfig>
    audio: HTMLAudioElement
    readonly musicLibrary: IMusicData
    readonly pd: UnwrapRef<IPrivateData>

    constructor(a: Activity, m: UnwrapRef<IModuleData>) {
        super(a, m, '基础设置', 'she_zhi', BasicStylePreview as DefineComponent, BasicStyleSetup as DefineComponent);
        this.attach = m.attach as IBasicAttach
        this.cfg = m.config as IBasicConfig

        this.pd = reactive({
            audio_status: false,
            audio_url: ''
        })

        // 自动更正数据
        if (!this.cfg.props) {
            this.cfg.props = {
                show_barrage: true,
                show_statistics: true,
                virtual: {view: 0, sign: 0, share: 0, pay: 0},
                is_show_pay_history: true
            }
            this.cfg.props.virtual.sign = Math.round(Random.integer(100, 200))
            this.cfg.props.virtual.view = Math.round(this.cfg.props.virtual.sign * 12.32)
            this.cfg.props.virtual.share = Math.round(this.cfg.props.virtual.sign * 2.56)
            this.cfg.props.virtual.pay = Math.round(this.cfg.props.virtual.sign * 0.84)
        }
        if (!this.cfg.music_url) this.cfg.music_url = ''

        // 活动音乐
        this.audio = new Audio()
        this.audio.onplay = () => this.pd.audio_status = true
        this.audio.onpause = () => this.pd.audio_status = false
        this.audio.onended = () => this.pd.audio_status = false
        this.musicLibrary = require('@/assets/music/music.json')

        setTimeout(() => {
            console.log(this.form?.validate())
        }, 5000)
    }

    previewTitle(title?: string): string {
        return title?.replace(/%(.+?)%/g, v => (this.activity.variable(v.replaceAll('%', '')) ?? '').toString()) ?? ''
    }

    // validate(): Promise<unknown> {
    //     if (!this.activity.data.title) return Promise.reject('活动名称未设置')
    //     if (!this.activity.data.theme_config.color) return Promise.reject('背景色未设置')
    //     if (!this.activity.data.theme_config.banner.image_id && !this.activity.data.theme_config.banner.image_url) return Promise.reject('首屏大图未设置')
    //     if (!this.activity.data.theme_config.share_card.image_id && !this.activity.data.theme_config.share_card.image_url) return Promise.reject('分享卡片封面未设置')
    //     if (!this.activity.data.theme_config.poster.image_id && !this.activity.data.theme_config.poster.image_url) return Promise.reject('分享海报未设置')
    //     return Promise.resolve();
    // }

    musicName(mUrl: string): string {
        for (const k in this.musicLibrary) {
            for (const item of ((this.musicLibrary as unknown as Json)[k] as IMusicDataCategory).data) {
                if (item.url == mUrl) return item.name
            }
        }
        return mUrl ? '自定义音乐' : ''
    }
}