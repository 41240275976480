import {DefineComponent} from "@vue/runtime-core";
import {UnwrapRef} from "vue";
import {Activity} from "@/app/editor/activity";
import {IModuleData} from "@/lib/activity";
import {FormExpose} from "ant-design-vue/es/form/Form";

export interface IModule {
    readonly title: string
    readonly icon: string
    readonly activity: Activity;
    readonly data: UnwrapRef<IModuleData>;
    readonly set_up: DefineComponent
    readonly preview: DefineComponent
    form: FormExpose | null


    validate(): Promise<unknown>
}

export class Module implements IModule {
    readonly title: string
    readonly icon: string
    readonly activity: Activity;
    readonly data: UnwrapRef<IModuleData>;
    public preview: DefineComponent;
    public set_up: DefineComponent;
    form: FormExpose | null = null;


    constructor(a: Activity, m: UnwrapRef<IModuleData>, title: string, icon: string, pc: DefineComponent, sc: DefineComponent) {
        this.activity = a
        this.data = m
        this.preview = pc
        this.set_up = sc
        this.title = title
        this.icon = icon
    }

    validate(): Promise<unknown> {
        return this.form?.validate() ?? Promise.resolve();
    }

    variable(name: string): string | null {
        return null
    }
}

