import {Module} from "@/app/editor/module";
import {UnwrapRef} from "vue";
import {Activity} from "@/app/editor/activity";
import {DefineComponent} from "@vue/runtime-core";
import {IModuleData} from "@/lib/activity";
import SignPreview from "@/app/editor/module/sign/v1/SignPreview.vue";
import SignSetup from "@/app/editor/module/sign/v1/SignSetup.vue";


export interface ISignConfig {
    inputs: IInput[]
}

export class Sign extends Module {
    cfg: UnwrapRef<ISignConfig>;
    readonly typeName = {'input': '文本项', 'radio': '单选', 'checkbox': '多选项', 'name': '姓名项'}

    constructor(a: Activity, m: UnwrapRef<IModuleData>) {
        super(a, m, '报名设置', 'gu_ke', SignPreview as DefineComponent, SignSetup as DefineComponent);
        this.cfg = m.config as UnwrapRef<ISignConfig>
        if (!this.cfg.inputs || this.cfg.inputs.length === 0) {
            this.cfg.inputs.unshift({required: true, title: '性别', type: 'gender', config: {type: 'text'}})
        }
        if (!this.cfg.inputs || this.cfg.inputs.length === 0 || !this.cfg.inputs.find(i => i.type == 'name')) {
            this.cfg.inputs.unshift({required: true, title: '联系人', type: 'name', config: {type: 'text'}})
        }
        if (!this.cfg.inputs || this.cfg.inputs.length === 0 || !this.cfg.inputs.find(i => i.type == 'phone_number')) {
            this.cfg.inputs.unshift({required: true, title: '手机号', type: 'phone_number', config: {type: 'number'}})
        }
        for (const input of this.cfg.inputs) {
            if (['phone_number', 'name', 'gender'].includes(input.type)) input.required = true
        }
    }
}

export interface IInput {
    required: boolean
    title: string
    type: 'input' | 'radio' | 'checkbox' | 'phone_number' | 'name' | 'gender'
    config: IInputTypeInput | IInputTypeRadio | IInputTypeCheckbox
}

export interface IInputTypeInput {
    type: 'text' | 'number' | 'date'
}


export interface IInputTypeRadio {
    items: string[]
}

export interface IInputTypeCheckbox extends IInputTypeRadio {
    max: number
    min: number
}