import {Module} from "@/app/editor/module";
import {reactive, UnwrapRef, watch} from "vue";
import {DefineComponent} from "@vue/runtime-core";
import {ITitle} from "@/app/editor/material/title";
import {ITheme} from "@/app/editor/material/theme";
import BasicStyleSetup from "@/app/editor/module/basic/v1/BasicStyleSetup.vue";
import {Activity} from "@/app/editor/activity";
import {Json, req} from "@/lib/util";
import {engine} from "@/app/editor/material/imager";
import BasicStylePreview from "@/app/editor/module/basic/v1/BasicStylePreview.vue";
import {Random} from "mockjs";
import emit from "@/lib/emit";
import {IModuleData} from "@/lib/activity";

interface IBasicAttach {
    titles: ITitle[]
    themes: ITheme[]
}

export interface IBasicVirtual {
    view: number
    sign: number
    share: number
    pay: number
}

export interface IBasicProps {
    show_barrage: boolean
    show_statistics: boolean
    virtual: IBasicVirtual
    is_show_pay_history: boolean
}

export interface IImageTemplateDrawOption {
    image_template_id: string
    id: number
    length_max: number
    length_min: number
    text: string
    title: string
}

export interface IThemeConfig {
    theme_id: string
    // [image_template_id][layer_id][config]
    theme_options: { [key: string]: IImageTemplateDrawOption[] }
    applet_thumb_version: number
    applet_poster_id: string
}

export interface IBasicConfig {
    music_url: string
    themes: string[]
    theme: IThemeConfig
    title_id: string
    props: IBasicProps
}

interface IMusicDataCategoryItem {
    name: string
    url: string
}

interface IMusicDataCategory {
    name: string
    data: IMusicDataCategoryItem[]
}

export interface IMusicData {
    child: IMusicDataCategory
    classical: IMusicDataCategory
    festival: IMusicDataCategory
    hot: IMusicDataCategory
    natural: IMusicDataCategory
    piano: IMusicDataCategory
    wangluo: IMusicDataCategory
}

export interface IPrivateData {
    audio_url: string
    audio_status: boolean
}

export class Basic extends Module {
    readonly attach: IBasicAttach
    cfg: UnwrapRef<IBasicConfig>
    audio: HTMLAudioElement
    readonly musicLibrary: IMusicData
    readonly pd: UnwrapRef<IPrivateData>
    private optionCache: { [p: string]: { [p: string]: IImageTemplateDrawOption[] } } = {}

    constructor(a: Activity, m: UnwrapRef<IModuleData>) {
        super(a, m, '基础设置', 'she_zhi', BasicStylePreview as DefineComponent, BasicStyleSetup as DefineComponent);
        this.attach = m.attach as IBasicAttach
        this.cfg = m.config as IBasicConfig
        a.data.theme_id = this.cfg.theme.theme_id

        this.pd = reactive({
            audio_status: false,
            audio_url: ''
        })

        // 自动更正数据
        if (!this.cfg.props) {
            this.cfg.props = {
                show_barrage: true,
                show_statistics: true,
                virtual: {view: 0, sign: 0, share: 0, pay: 0},
                is_show_pay_history: true
            }
            this.cfg.props.virtual.sign = Math.round(Random.integer(100, 200))
            this.cfg.props.virtual.view = Math.round(this.cfg.props.virtual.sign * 12.32)
            this.cfg.props.virtual.share = Math.round(this.cfg.props.virtual.sign * 2.56)
            this.cfg.props.virtual.pay = Math.round(this.cfg.props.virtual.sign * 0.84)
        }
        if (!this.cfg.music_url) this.cfg.music_url = ''
        if (!this.cfg.themes) this.cfg.themes = []
        if (!this.cfg.theme) this.cfg.theme = {
            applet_poster_id: "",
            applet_thumb_version: 1,
            theme_id: '',
            theme_options: {}
        }
        if (!this.cfg.theme.applet_thumb_version) this.cfg.theme.applet_thumb_version = 1
        if (!this.cfg.title_id) this.cfg.title_id = ''
        const t = this.attach.themes.find(t => t.theme_id == this.cfg.theme.applet_poster_id)
        if (!t) {
            this.cfg.theme.applet_poster_id = this.attach.themes.first()?.applet_poster_image_template_ids.first() ?? ''
        } else if (!t.applet_poster_image_template_ids.includes(this.cfg.theme.applet_poster_id)) {
            this.cfg.theme.applet_poster_id = t.applet_poster_image_template_ids.first() ?? ''
        }

        // 活动音乐
        this.audio = new Audio()
        this.audio.onplay = () => this.pd.audio_status = true
        this.audio.onpause = () => this.pd.audio_status = false
        this.audio.onended = () => this.pd.audio_status = false
        this.musicLibrary = require('@/assets/music/music.json')


        // 动态连接
        emit.on('activity.code.change', () => {
            this.refreshThemeView()
            this.activity.data.title = this.previewTitle(this.attach.titles.find(t => t.title_id == this.cfg.title_id)?.title)
        })
        watch(this.cfg.theme.theme_options,() => {
            this.refreshThemeView()
        })
    }

    previewTitle(title?: string): string {
        return title?.replace(/%(.+?)%/g, v => (this.activity.variable(v.replaceAll('%', '')) ?? '').toString()) ?? ''
    }

    validate(): Promise<unknown> {
        if (!this.cfg.theme.theme_id) return Promise.reject('主题没有选择')
        if (!this.cfg.theme.applet_thumb_version) return Promise.reject('没有指定分享卡片封面')
        if (!this.cfg.theme.applet_poster_id) return Promise.reject('没有指定分享海报')
        if (!this.cfg.title_id) return Promise.reject('没有设置标题')
        return Promise.resolve()
    }

    musicName(mUrl: string): string {
        for (const k in this.musicLibrary) {
            for (const item of ((this.musicLibrary as unknown as Json)[k] as IMusicDataCategory).data) {
                if (item.url == mUrl) return item.name
            }
        }
        return mUrl ? '自定义音乐' : ''
    }

    themeSwitch(themeId: string, fn: () => void) {
        if (themeId in this.optionCache) {
            this.mergeThemeOption(themeId, this.optionCache[themeId])
            fn()
            return
        }
        req({
            url: 'editor/material/theme/options',
            params: {theme_id: themeId},
            success: (rs: { [p: string]: IImageTemplateDrawOption[] }) => {
                this.mergeThemeOption(themeId, rs)
            },
            complete: fn
        })
    }

    private mergeThemeOption(themeId: string, rs: { [p: string]: IImageTemplateDrawOption[] }) {
        // 自动合并本地参数
        for (const k in rs) {
            for (let i = 0; i < rs[k].length; i++) {
                rs[k][i].text = this.getOption(rs[k][i].title) ?? rs[k][i].text
                // console.log('set rs', k, i, rs[k][i].title, ':', this.getOption(rs[k][i].title))
            }
        }
        this.optionCache[themeId] = rs
        this.cfg.theme.theme_options = rs
        this.cfg.theme.theme_id = themeId
        this.cfg.theme.applet_thumb_version = 1
        this.cfg.theme.applet_poster_id = this.attach.themes.find(t => t.theme_id == themeId)?.applet_poster_image_template_ids.first() ?? ''
        this.activity.data.theme_id = themeId

        this.refreshThemeView()
        this.loadShowThemes()
    }

    private getOption(title: string): string | null {
        for (const k in this.cfg.theme.theme_options) {
            for (let i = 0; i < this.cfg.theme.theme_options[k].length; i++) {
                if (this.cfg.theme.theme_options[k][i].title == title) return this.cfg.theme.theme_options[k][i].text
            }
        }
        return null
    }

    onOptionChange(evt: InputEvent, imageId: string, k: number) {
        this.cfg.theme.theme_options[imageId][k].text = evt.data ?? ''
        for (const nk in this.cfg.theme.theme_options) {
            for (let i = 0; i < this.cfg.theme.theme_options[nk].length; i++) {
                if (this.cfg.theme.theme_options[nk][i].title == this.cfg.theme.theme_options[imageId][k].title) this.cfg.theme.theme_options[nk][i].text = evt.data ?? ''
            }
        }
        this.refreshThemeView()
    }

    refreshThemeView() {
        const theme = this.attach.themes.find(t => t.theme_id == this.cfg.theme.theme_id)
        if (theme) {
            engine.send({
                key: theme.head_image_template_id,
                image_url: "",
                image_id: theme.head_image_template_id,
                base_image_url: '',
                values: this.activity.data.theme_image_values[theme.head_image_template_id]
            })
            engine.send({
                key: theme.applet_share_thumb_image_template_id + this.cfg.theme.applet_thumb_version,
                image_url: "",
                image_id: theme.applet_share_thumb_image_template_id,
                base_image_url: '',
                values: this.activity.data.theme_image_values[theme.applet_share_thumb_image_template_id]
            })
            engine.send({
                key: this.cfg.theme.applet_poster_id,
                image_id: this.cfg.theme.applet_poster_id,
                base_image_url: '',
                image_url: '',
                values: this.activity.data.theme_image_values[this.cfg.theme.applet_poster_id]
            })
            // engine.send(theme.applet_share_thumb_image_template_id, this.cfg.theme.applet_thumb_version)
            // engine.send(this.cfg.theme.applet_poster_id, 0)
        }
    }

    loadShowThemes() {
        for (let i = 0; i < this.cfg.themes.length; i++) {
            const iid = this.attach.themes.find(t => t.theme_id == this.cfg.themes[i])?.head_image_template_id
            // console.log('load show theme image:',iid)
            if (iid) engine.send({
                key: iid,
                image_url: "",
                image_id: iid,
                base_image_url: '',
                values: this.activity.data.theme_image_values[iid]
            })
            // if (iid) engine.send(iid, 0)
        }
    }

    loadWxThumbImages(id: string) {
        Array(6).fill(null).map((_, ii) => ii + 1).forEach(i => {
            engine.send({
                key: id + i,
                image_url: "",
                image_id: id,
                base_image_url: '',
                values: {
                    0: {
                        type: 'through_train',
                        title: '',
                        value: i.toString(),
                        max: 10,
                        min: -1
                    }
                }
            })
            // engine.send(id, i)
        })
    }

}